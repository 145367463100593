<template>

  <div>

    <section class="home-section intro">
      <div class="container parent-element">
        <div class="child-element keep">

          <div class="intro-right parent-element">
            <div class="child-element">
              <!-- <h1>Real time safety checklists<br>and maintenance logs for your<br>truck or bus with zero paper!</h1> -->
              <h1>If you're a truck or bus driver, then you should be using SafeTruckie for all your Pre-start Safety Checks, Servicing and Maintenance Logs!</h1>
              <div class="spacer s30">
                
              </div>
              <div class="buttons has-text-centered">
                <router-link class="button is-success is-medium" to="sign-up">Sign Up Now</router-link>
                <a class="button is-success is-inverted is-outlined is-medium" href="#more-info" v-smooth-scroll>Read More</a>
              </div>
              <!-- <div class="buttons has-text-centered">
                <span class="button is-info is-medium" @click="toSignUp">Or Create a Free Account</span>
              </div>    -->
            </div>
          </div>

          <div class="intro-left">
            <img class="partners" src="@/assets/img/hero-devices.png" />
          </div>

          <div class="clear"></div>


              

          <!-- <span class="simple-link" @click="viewDEMO()">Click here to view a Demo SMS</span> -->

          <!-- <div class="scroll-more">
            <div class="scroll-text">Or scroll down for more information</div>
            <div class="down-arrow"></div>
          </div> -->

        </div>
      </div>
      <div class="scroll-more">
        <!-- <div class="scroll-text">Or scroll down for more information</div> -->
        <div class="down-arrow"></div>
      </div>
    </section>

    <section id="more-info" class="home-section white cloud">
      <div class="container">
          <div class="has-text-centered">  

                <h2>Download our Free apps</h2> 
                <p>Full access to all app features for 2 months - no payment required.<br>Plus! All users get a free web Fleet Dashboard to manage multple trucks in real time!</p>
                <div class="spacer s20"></div>

                <div class="download">
                  <a @click="trackClick('click_app_store_link')" href="https://apps.apple.com/us/app/safetruckie/id1538634148" target="_blank"><img class="partners" src="@/assets/img/app-store.jpg" /></a>
                  <a @click="trackClick('click_play_store_link')" href="https://play.google.com/store/apps/details?id=com.sms.safetruckie" target="_blank"><img class="partners" src="@/assets/img/play-store.jpg" /></a>
                </div>

                <div class="clear"></div>
                <!-- <div class="spacer s50"></div> -->

                <!-- <div class="icon"><font-awesome-icon icon="cloud" /></div>  -->
                <!-- <h2>Includes a Free Fleet Dashboard</h2>
                <p>Full access to all app features for 2 months - no payment required. After that, pay only $39 per truck per year!</p>
                <div class="spacer s20"></div> -->
                <!-- <router-link class="button is-info is-medium" to="how-it-works">How does it work?</router-link> -->
                <!-- <span class="button is-info is-medium" @click="viewDEMO()">View a Demo SMS</span> -->
                <!-- <router-link :to="setRoute" class="button is-info is-medium">{{isLoggedIn ? 'My Dashboard' : 'Sign Up Now'}}</router-link> -->
          </div>
      </div>
    </section>


   <!--  <section class="home-section dark-blue amsa">
      <div class="container parent-element">
          <div class="child-element">    
                <div class="icon"><font-awesome-icon icon="life-ring" /></div>
                <h2>AMSA Compliant Safety Management*</h2>
                <p>Meet all of your commercial vessel safety obligations in one place. What's more, you can share your SMS and safety logs with AMSA at any time.</p>
                <p class="asterisked is-size-6">*An SMS that is compliant with Marine Order 504 requires regular input and review, plus regular daily startup checklists, crew inductions, maintenance logs and incident reports. OffshoreSMS makes this easy.</p>
                <hr>
                <router-link class="button is-info is-inverted is-medium" to="safety-obligations">My Safety Obligations</router-link>
          </div>
      </div>
    </section> -->

    <!-- <section class="home-section white">
      <div class="container parent-element">
          <div class="child-element">  
                <div class="icon"><font-awesome-icon icon="check-circle" /></div>  
                <h2>Daily startups and crew inductions on the go</h2>
                <p>As long as you have internet access, you can do your daily pre-operational startup checks, induct a new crew member, log any maintenance work or record an incident, right from your mobile phone!</p>
                <p>&nbsp;</p>
                <span class="button is-info is-medium" @click="viewDEMO()">View a Demo SMS</span>
          </div>
      </div>
    </section> -->


    <section id="pricing" class="home-section dark-blue">
      <div class="container parent-element">
          <div class="child-element">    
                <!-- <div class="icon"><font-awesome-icon icon="money-bill-alt" /></div> -->
                <img class="" src="@/assets/img/dash-truck.png" />
                <h2>Just $39 per year with a Free 2 Month Trial</h2>
                <p>All the awesome features with one single annual payment. No mess. no fuss.<br>Get full access to all app features for 2 months - no payment required.<br>After that, pay only $39 per truck per year.</p>
                <hr>
                <div class="clear"></div>
                <div class="spacer s10"></div>
                <router-link class="button is-success is-inverted is-medium" to="sign-up">Sign up for Free</router-link>
          </div>
      </div>
    </section>

<!-- 
    <section class="home-section white">
      <div class="container parent-element">
          <div class="child-element">  
                <div class="icon"><font-awesome-icon icon="thumbs-up" /></div>  
                <h2>Get started!</h2>
                <p>Click the link below to sign up and get your safety sorted today.</p>
                <p>It is FREE to start and we offer a 30 Day 100% Money Back Guarantee!</p>
                <p>&nbsp;</p>
                <router-link class="button is-info is-medium" to="sign-up">Let's go!</router-link>
          </div>
      </div>
    </section> -->


   <!--  <section class="home-section swirling">

    </section> -->


    <!-- <section class="home-section white">
      <div class="container parent-element">
          <div class="child-element">  
                <h2>Tell us what you think</h2>
                <p>We value your thoughts - please take a moment to tell us what you think and how we might improve Offshore SMS.</p>
                <p>&nbsp;</p>
                <router-link class="button is-info is-medium" to="feedback">Leave feedback</router-link>
          </div>
      </div>
    </section> -->


    <!-- <section class="home-section white">
      <div class="container parent-element">
          <div class="child-element">  
                <h2>Own a fleet of vessels?</h2>
                <p>We are developing a world-class safety management dashboard for commercial vessel fleets. For more information please call<br>0409 709 596.</p>
          </div>
      </div>
    </section> -->

<!-- 
    <section class="home-section white">
      <div class="container parent-element">
          <div class="child-element">  
                <h2>Footer</h2>
          </div>
      </div>
    </section> -->

    <footer>
      Copyright &copy;  SafeTruckie 2020
    </footer>





  </div>

</template>

<script>

	// import { store } from '../store/store';
  import firebase from 'firebase/app';
  import 'firebase/auth'; 

	export default {
		

    beforeRouteEnter (to, from, next) {
      next(vm => {
        // access to component instance via `vm`
        let user = firebase.auth().currentUser;
        let firstLoad = (from.name == null ? true : false);
        if (user && firstLoad) { 
          // if (this.userRole.userrole == 'fleet') {
          //   vm.$router.replace('my-dashboard'); 
          // } else {
          //   vm.$router.replace('fleet-dashboard'); 
          // }
          vm.$router.replace('my-dashboard'); 
        }
      })
    },

    mounted: function() {
      firebase.auth().onAuthStateChanged( this.onUserLogin );     
    },

    data: function(){ 
      return {
        //introContent: "Create your commercial vessel safety management system in the cloud, and access it anywhere, anytime.",
        introContent: 'The easiest way to get a compliant Safety Management System for your commercial vessel.',
        firebaseid: '',
      }
    },
    computed: {
      isUserAdmin() {
        return this.$store.getters.getIsUserAdmin;
      },
      userData() {
        return this.$store.getters.getSubscriptionInfo;
      },
      isLoggedIn() {
        return this.firebaseid != '';
      },
      setRoute() {
        return this.isLoggedIn ? 'my-dashboard' : 'sign-up';
      }
    },

    methods: {

      onUserLogin: function( user ) {
        if (user) {         
          this.firebaseid = user.uid;          
        }
      },
      
      viewDEMO: function() {
        let vesselName = 'DEMO VESSEL';
        let vesselID = 'kZgrsNOrggKmi7bjE5IA';
        let vesselUVI = 'DEMO';
        //this.$store.dispatch('UPDATESELECTEDVESSEL',{'DEMO VESSEL','kZgrsNOrggKmi7bjE5IA','DEMO'});
        this.$store.dispatch('UPDATESELECTEDVESSEL',{vesselName,vesselID,vesselUVI});
        this.$router.push({ name: 'DemoBuilder', params: { 
          selectedVesselID : 'kZgrsNOrggKmi7bjE5IA',
          selectedVesselName : 'DEMO VESSEL',
          selectedVesselUVI : 'DEMO',
          }
        });
      },

      toSignUp: function() {
        this.$router.push({ name: 'signUp', params: { 
            planType: 'free',
            maxVessels: 0
          }
        });
      },

      trackClick(type) {
        console.log('Event clicked:', type);
        this.$gtag.event(type, {
          'event_category': 'Button Clicks',
          'event_label': type,
          'value': 1
        });
      },



      

    },

	}


</script>

<style lang="scss" scoped>

.download {
  margin-bottom: 2rem;
  a {
    display: inline-block;
    max-width: 270px;
  }
}

.counter-wrap {
	margin-bottom: 2rem;
}

img.partners {
  width: 90%;
  max-width: 400px;
}

.simple-link {
  color: #fff;
  &:visited {
    color: #fff;
  }
}

.buttons {
  .button {
    display: inline-block;
    height: auto;
    line-height: 1.5rem;
    padding: 20px 15px;
    white-space: normal;
  }
}

section {
  width: 100%;
  margin: 0;
  &.home-intro {
    background: #205f88;
    background: url('../assets/img/hero-truck-1.jpg') no-repeat center center;
    background-size: cover;
    height: 400px;
    width: 100%;
    margin-top: -30px;
    text-align: center;
    padding: 0 3rem;    
    h1 {
      color: #fff;
      font-size: 1.8rem;
      line-height: 2rem;
    }
  }
}

footer {
  display: block;
  background: #fff;
  padding: 3rem 0;
  position: relative;
}


.home-section {
  width: 100%;
  padding: 5rem 0;
  .container {
    max-width: 1000px;
    text-align: center;
    height: 530px;
  }
  .icon {
    font-size: 7rem;
    margin-bottom: 4.5rem;
  }
  h2 {
    color: inherit;
    font-size: 2rem;
    line-height: 2.3rem;
    margin-bottom: 1rem;
  }
  p {
    font-size: 1.1rem;
    line-height: 1.8rem;
    color: inherit;
    text-align: center;
    &.emphasis {
      font-style: italic;
    }
    &.asterisked {
      font-style: italic;
      font-size: 1.1rem;
      line-height: 2rem;
      margin-top: 1.2rem;
    }
  }
  &.dark-blue {
    color: #fff;
    background: #205f88;
    padding: 5rem 1rem;
    hr {
      border-color: #427ba1;
    }
  }
  &.white {
    padding: 5rem 1rem;
    color: #245e8b;
    .icon {
      color: #089bf9;
    }
  }
  &.cloud {
    padding: 4rem 1rem;
    height: auto;
    .container {
      height: auto;
    }
  }
  &.amsa {
    padding: 5rem 1rem;
  }
  &.swirling {
    background: url('../assets/img/offshore-sms-swirling-sea.jpg') no-repeat center center;
    background-size: cover;
    height: 300px;
    width: 100%;
  }
  &.intro {
    background: url('../assets/img/hero-truck-1.jpg') no-repeat center center;
    background-size: cover;
    width: 100%;
    margin-top: -30px;
    text-align: center;
    padding: 5rem 1rem;
    position: relative;
    height: auto;
    min-height: 700px;
    min-height: 70vh;
    h1 {
      color: #fff;
      font-size: 1.8rem;
      line-height: 2rem;
    }
    .intro-left {
      float: left;
      width: 50%;
    }
    .intro-right {
      float: right;
      width: 50%;
      height: 400px;
    }
  }
  .scroll-more {
    width: 140px;
    margin: 1rem auto;
    position: absolute;
    bottom: 5px;
    left: 50%;
    transform: translateX(-70px);
    .scroll-text {
      color: #fff;
      font-size: 0.8rem;
    }
    .down-arrow {
      width: 42px;
      height: 42px;
      margin: 0.5rem auto;
      background: url('../assets/img/down-arrow.png') no-repeat center center;
      background-size: cover;
    }
  }


}


@media only screen and (max-width: 479px) {

  .buttons {
    .button {
      display: block;
      height: auto;
      font-size: 1.1rem;
      line-height: 1.1rem;
      padding: 12px 6px;
    }
  }


}


</style>
