import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router/routes'
import store from './store/store'

// Vue.prototype.$store = store;

// import VueRouter from 'vue-router';
// import { store } from './store/store';
// import { routes } from './router/routes';


Vue.config.productionTip = false

import firebase from 'firebase/app';
import 'firebase/auth';        // for authentication
import 'firebase/storage';     // for storage
import 'firebase/firestore';   // for cloud firestore
//import 'firebase/messaging';   // for cloud messaging
import 'firebase/functions';   // for cloud functions

import VueGtag from "vue-gtag";

Vue.use(VueGtag, {
  config: { id: "G-33GR6RVRCT" }
}, router);

// Vue.use(VueRouter);

// const router = new VueRouter({
//   routes: routes,
//   mode: 'history'
// });

import './assets/css/style.scss';


// Dependencies =============================================================================================
import NProgress from 'vue-nprogress';
Vue.use(NProgress);

import Datetime from 'vue-datetime'
// You need a specific loader for CSS files
import 'vue-datetime/dist/vue-datetime.css' 
Vue.use(Datetime)

import VueSignaturePad from 'vue-signature-pad'; 
Vue.use(VueSignaturePad);

import moment from 'moment';
Vue.use(moment);

import vueSmoothScroll from 'vue2-smooth-scroll'
Vue.use(vueSmoothScroll)

// import * as VueGoogleMaps from 'vue2-google-maps'

import VuejsDialog from 'vuejs-dialog';
import 'vuejs-dialog/dist/vuejs-dialog.min.css';
Vue.use(VuejsDialog);

// import dropzone from 'vue2-dropzone';
import "vue2-dropzone/dist/vue2Dropzone.min.css";







// Font Awesome =============================================================================================
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { 
  faCoffee, faUserAlt, faShip, faExclamationTriangle, faUser, faUsers, faFish, faFire, faHome, faBriefcase, faUserGraduate, faCloud, faLifeRing, faCheckCircle, faMoneyBillAlt, faThumbsUp, faLink, faInfo, faInfoCircle, faQuestionCircle, faCog, faList, faTimes, faCheck, faEnvelope, faPhone, faChevronUp, faChevronDown, faChevronLeft, faChevronRight, faLock, faShoppingCart, faWrench, faStopwatch, faOilCan, faStar, faVolumeUp, faPrint, faRegistered,
  faFileSignature, faListUl, faClipboardList, faAnchor, faPlay, faPen, faSearch, faCamera, faCalendarCheck, faPlus, faTruck, faTrashAlt, 
} from '@fortawesome/free-solid-svg-icons'
import {
  // faLinkedin,
  // faTwitter,
  // faFacebookF,
  //faInstagram,
} from '@fortawesome/free-brands-svg-icons';
library.add(faCoffee, faUserAlt, faShip, faUsers, faUser, faFish, faExclamationTriangle, 
  faFire, faHome, faBriefcase, faUserGraduate, faCloud, faLifeRing, faCheckCircle, faMoneyBillAlt, 
  faThumbsUp, faLink, faInfoCircle, faInfo, faQuestionCircle, faCog, faList, faTimes, faCheck, faEnvelope, faPhone,
  faChevronUp, faChevronDown, faLock, faShoppingCart, faWrench, faStopwatch, faOilCan, faStar, faChevronLeft,
  faChevronRight, faVolumeUp, faPrint, faFileSignature, faListUl, faClipboardList, faAnchor, faPlay, faPen, faRegistered, faSearch, faCamera, 
  faCalendarCheck, faPlus, faTruck, faTrashAlt,   )

Vue.component('font-awesome-icon', FontAwesomeIcon);





const firebaseConfig = {
  apiKey: "AIzaSyBMQG1XBxc0gTb-58Z7GVX79NzrlHhuL60",
  authDomain: "safetruckie.firebaseapp.com",
  databaseURL: "https://safetruckie.firebaseio.com",
  projectId: "safetruckie",
  storageBucket: "safetruckie.appspot.com",
  messagingSenderId: "942499057655",
  appId: "1:942499057655:web:af4583c565e69dce3fe8f2",
  measurementId: "G-W2C6LRL885"
};

firebase.initializeApp(firebaseConfig);
// firebase.analytics();

export const db = firebase.firestore();










// Check before each page load whether the page requires authentication/
// if it does check whether the user is signed into the web app or
// redirect to the sign-in page to enable them to sign-in


router.beforeResolve((to, from, next) => {
  // If this isn't an initial page load.
  if (to.name) {
      // Start the route progress bar.
      // NProgress.start()
  }
  next();
});


router.beforeEach((to, from, next) => {

  const currentUser = firebase.auth().currentUser;
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);

  if (requiresAuth && !currentUser) {
    next('/sign-in');
  } else if (requiresAuth && currentUser) {
    window.scrollTo(0, 0)
    next();
  } else {    
    window.scrollTo(0, 0)
    next();
  }

});


router.afterEach(() => {
  // Complete the animation of the route progress bar.
  // console.log(to, from);
  // NProgress.done()
});





// firebase.auth().onAuthStateChanged(function (user) {
firebase.auth().onAuthStateChanged(function () {

  // console.log('User: ', user);

  new Vue({
    router,
    store,
    render: h => h(App)
  }).$mount('#app')

  // new Vue({
  //   el: '#app',
  //   store: store,
  //   router: router,
  //   render: h => h(App),
  // });

});



